import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.API_BASE_URL,
  headers: {
    Accepted: "appication/json",
    "Content-Type": "application/json",
    api_key: process.env.EVENT_PUBLIC_API_KEY,
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const user = JSON.parse(localStorage.getItem("au"));
    if (user) {
      config.headers.authorization = user.atkn;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default axiosInstance;
