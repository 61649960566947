import React from "react";
import { useParams } from "react-router-dom";
import AppLayout from "../../layouts/AppLayout";
import TicketSelection from "../containers/events/TicketSelection";

const BuyTicket = ()  => {
  const params = useParams();
  const {eventId} = params;
  return(
      <AppLayout>
        <TicketSelection eventId={eventId}/>
      </AppLayout>
    )}

export default BuyTicket;
