import React from "react";
import "../../../assets/styles/components/img_text.css";
import background from "../../../assets/images/background.svg";

export default ({ content, isBasicEvent }) => {
  const { eventData, content2, basicContent } = content;

  return eventData.id ? (
    <div
      className="row-space-between"
      id="introduction"
      style={{
        background: `url(${background})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "top left",
        animation: "wave 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite",
        transform: "translate3d(0, 0, 0)",
      }}
    >
      <div className="intContent2">
        {isBasicEvent ? basicContent : content2}
      </div>
      <div className="intContent1">
        <div>
          <img
            src={eventData.coverPhoto}
            alt="most voted"
            id="introduction_img"
          />
        </div>
      </div>
    </div>
  ) : null;
};
