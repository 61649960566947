/* eslint-disable no-plusplus */
import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { Button } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import { fullUrlAction } from "../../../redux/action";
import { FETCH_EVENT } from "../../../redux/events/types";
import SelectTicket from "../../../components/TicketSelector";
import Loading from "../../../components/Loading";
import TicketPayment from "../../components/ticketPaymentComponent/PaymentEntry";
import { formatDate, formatTime } from "../../../helpers/helperMethods";

const style = {
  height: "100%",
  "@media(max-width:600px)": {
    width: "90%",
    padding: "10px",
    paddingTop: "30px",
  },
  padding: "0px",
  bgcolor: "background.paper",
  // border: '2px solid #000',
  borderRadius: "10px",
  boxShadow: 4,
  p: 4,
  marign: "100px 0px",
};
const divider = {
  marginBottom: "0px",
};
const avatar = {
  "@media(max-width:600px)": { left: "43%" },
  padding: "10px",
  top: -30,
  backgroundColor: "red",
  objectFit: "cover",
};
const typography = {
  display: "flex",
  justifyContent: "center",
};

export default function BuyTickets({ eventId }) {
  const dispatch = useDispatch();

  const eventInfo = useSelector((state) => state.event);

  const [currency, setCurrency] = React.useState("RWF");

  const eventSort =
    (eventInfo.data.results &&
      eventInfo.data.results.payment.ticketsOptions.sort((a, b) => {
        const pricea = a.prices.find((el) => el.currency === currency);
        const priceb = b.prices.find((el) => el.currency === currency);
        return pricea.amount - priceb.amount;
      })) ||
    [];
  
  const [tickets, setTickets] = React.useState([]);
  const defaultEventIndex = eventSort.findIndex(item => item.remainingPlaces !== 0);
  const [totalCost, setTotalCost] = React.useState(0);
  const [Oneticket, setOneTicket] = React.useState(0);

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    if (
      !eventInfo.data.results ||
      eventId !== (eventInfo.data.results && eventInfo.data.results.id)
    ) {
      dispatch(
        fullUrlAction({
          method: "GET",
          path: `${process.env.EVENT_PUBLIC_API}/events/${eventId}`,
          actionType: FETCH_EVENT,
        })
      );
    }
  }, []);

  React.useEffect(() => {
    const uniqueTickets =
      tickets &&
      Array.from(new Set(tickets.map((a) => a.ticketType)))
        .reverse()
        .map((ticketType) => tickets.find((a) => a.ticketType === ticketType))
        .filter((item) => item.numberofTickets !== 0);
    const cost = uniqueTickets.map((c) => +c.Price * c.numberofTickets);
    setTotalCost(cost.reduce((a, b) => a + b, 0));
  }, [tickets]);

  const handleCurrency = (event) => {
    setCurrency(event.target.value);
  };

  return eventInfo.loading || !eventInfo.data.results ? (
    <Loading />
  ) : (
    <div>
      <Box
        sx={{
          backgroundColor: "rgb(9, 47, 83)",
          padding: "5px",
          marginBottom: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "5px 20px",
            width: "fit-content",
          }}
        >
          <Box
            sx={{
              paddingRight: "20px",
              "@media(max-width:600px)": { display: "none" },
            }}
          >
            <img
              src={eventInfo.data.results.coverPhoto}
              style={{
                width: "200px",
                height: "125px",
                objectFit: "cover",
                objectPosition: "top center",
              }}
            />
          </Box>
          <Box>
            <Typography
              variant="h5"
              sx={{ color: "white", fontWeight: "bold" }}
            >
              {eventInfo.data.results.name}
            </Typography>
            <Typography sx={{ color: "white", fontWeight: "bold" }}>
              {eventInfo.data.results.venue}
            </Typography>
            <Typography sx={{ color: "#d4d2d2", fontWeight: "400px", fontSize:"15px" }}>
              contact Us<br/> phone: +250788244702 <br/>
              email: <a href="#">info@inyarwanda.com</a>
            </Typography>
          </Box>
        </Box>
      </Box>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "88px 0px",
        }}
      >
        <Box sx={style} elevation={0}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              height: "93%",
              "@media(max-width:600px)": {
                flexDirection: "column",
                maxHeight: "fit-content",
              },
            }}
          >
            <Box
              sx={{
                borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                width: "60%",
                "@media(max-width:600px)": { width: "98%" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ padding: "10px", fontSize: "18px" }}>
                  Choose Your Tickets
                </Typography>
                <FormControl sx={{ width: "100px", paddingRight: "20px" }}>
                  <Select
                    value={currency}
                    onChange={handleCurrency}
                    sx={{
                      width: "100%",
                      height: "40px !important",
                      marginLeft: "10px",
                    }}
                    displayEmpty
                    disabled={tickets.length > 0 && "disabled"}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    {eventInfo.data.results.payment.currency.map(
                      (item, index) =>(
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Box>
              <Divider sx={divider} />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ color: "rgba(0,0,0,0.72)" }}>Date</Typography>
                <Box sx={{ padding: "20px", textAlign: "center" }}>
                  <Typography
                    sx={{ fontSize: "15px", color: "rgba(0,0,0,0.72)" }}
                  >
                    {formatDate(eventInfo.data.results.endDate)}
                  </Typography>
                  <Typography
                    sx={{ fontSize: "13px", color: "rgba(0,0,0,0.42)" }}
                  >
                    Doors Open( {formatTime(eventInfo.data.results.checkinTime)})
                  </Typography>
                </Box>
              </Box>
              <Divider sx={divider} />
              {eventSort.map((item, index) => (
                <SelectTicket
                  key={index}
                  ticketType={item.name}
                  price={item.prices.map(
                    (price) => currency === price.currency && price.amount
                  ).filter(items => items !==false)}
                  selectedTickets={tickets}
                  getSelectedTickets={setTickets}
                  ticket={Oneticket}
                  getTicket={setOneTicket}
                  currency={currency}
                  defaultNumTickets={index === defaultEventIndex && item.remainingPlaces !== 0 ? 1 : 0}
                  tierPlaces={item.remainingPlaces}
                  stopOnline={item.stopOnline}
                />
              ))}

              <Box
                sx={{
                  width: "90%",
                  padding: "10px 20px",
                  "@media(max-width:600px)": { display: "none" },
                }}
              >
                <Button
                  disabled={
                    (tickets.length === 0 || open === true || eventInfo.data.results.payment.remainingPlaces === 0) && "disabled"
                  }
                  onClick={() => {
                    setTickets((prev) => {
                      const data = Array.from(new Set(prev.map((a) => a.ticketType))).reverse()
                          .map((ticketType) => prev.find((a) => a.ticketType === ticketType)).map((item, index) => ({
                        ...item,
                        nominalId: index + 1,
                      }));

                      return data;
                    });
                    setOpen(() => !open);
                  }}
                  variant="contained"
                  elevation={0}
                  sx={{
                    width: "100%",
                    padding: "15px 30px 15px 0px",
                    backgroundColor: "#044368",
                    "&:hover": { backgroundColor: "#044368" },
                  }}
                >
                  Check-out
                </Button>
              </Box>
            </Box>
            {/* Ticket information */}
            <Divider />
            <TicketPayment
              open={open}
              title={`${eventInfo.data.results.name} Tickets Payment`}
              quantityLabel="tickets"
              type="events"
              id={eventId}
              onCloseModel={handleClose}
              description={`Noneho events payment | Confirm payment for your selected tickets`}
              completeMessage={`Thank you for buying ticket(s), Your ticket(s) will be sent after payment is completed`}
              payload={{
                eventId,
                eventType: eventInfo.data.results.category,
                clientId: eventInfo.data.results.clientId,
                tickets: tickets.map((t) => ({
                  tierPrice: t.Price,
                  tierName: t.ticketType,
                  numberofTickets: t.numberofTickets,
                })),
              }}
              amount={totalCost}
              currency={currency}
              customer={{}}
            ></TicketPayment>
            <Box
              sx={{
                textAlign: "start",
                width: "100%",
                "@media(max-width:600px)": {
                  width: "100%",
                  marginTop: "20px",
                  marginBottom: "10px",
                },
                backgroundColor: "rgb(161 159 159 / 18%)",
              }}
            >
              <Typography sx={{ padding: "20px", fontSize: "20px" }}>
                Your Tickets
              </Typography>
              <Divider />
              <Box>
                {tickets &&
                  Array.from(new Set(tickets.map((a) => a.ticketType)))
                    .reverse()
                    .map((ticketType) =>
                      tickets.find((a) => a.ticketType === ticketType)
                    )
                    .filter((item) => item.numberofTickets !== 0)
                    .map((t) => (
                      <Box
                        key={t.ticketType}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "10px 10px",
                        }}
                      >
                        <Box sx={{ opacity: 0.5 }}>
                          {t.numberofTickets}{" "}
                          <span
                            style={{ padding: "0px 10px", fontSize: "15px" }}
                          >
                            x
                          </span>{" "}
                          {t.ticketType}
                        </Box>
                        <Box sx={{ opacity: 0.5 }}>
                          {+t.Price * t.numberofTickets} {currency}
                        </Box>
                      </Box>
                    ))}
                <Divider />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "10px",
                  }}
                >
                  <Box sx={{ opacity: 0.5 }}>Total</Box>
                  <Box>
                    {totalCost} {currency}
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  width: "90%",
                  padding: "10px 20px",
                  "@media(min-width:600px)": { display: "none" },
                }}
              >
                <Button
                  disabled={
                    (tickets.length === 0 || open === true || eventInfo.data.results.payment.remainingPlaces === 0) && "disabled"
                  }
                  onClick={() => {
                    setTickets((prev) => {
                      const data = Array.from(new Set(prev.map((a) => a.ticketType))).reverse()
                          .map((ticketType) => prev.find((a) => a.ticketType === ticketType)).map((item, index) => ({
                        ...item,
                        nominalId: index + 1,
                      }));
                      // prev.map((item, index) => {
                      //   for (let i = 1; i < item.numberofTickets; i++) {
                      //     data.push({ ...item, nominalId: data.length + 1 });
                      //   }
                      //   return { ...item, nominalId: index + 1 };
                      // });

                      return data;
                    });
                    setOpen(() => !open);
                  }}
                  variant="contained"
                  elevation={0}
                  sx={{
                    width: "100%",
                    padding: "15px 30px 15px 0px",
                    backgroundColor: "#044368",
                    "&:hover": { backgroundColor: "#044368" },
                  }}
                >
                  Check-out
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </div>
    </div>
  );
}
