import React, { useState } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, CardActions } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useHistory } from "react-router";
import PulseLoader from "react-spinners/PulseLoader";
import Skeleton from "@mui/material/Skeleton";
import {
  formatNumber,
  navigateToPage,
  useWindowDimensions,
} from "../../helpers/helperMethods";
import { grayColor } from "../../assets/styles/base";
import ConfirmDialog from "../Dialog/CustomDialog";
import Toast from "../MessageToast";
import fullUrlService from "../../services/fullUrlService";

const useStyles = makeStyles(() => ({
  contestantCard: {
    height: "40vh",
    width: "100%",
    "@media(max-width:600px)": {
      height: "fit-content"
    }
  },
  root: {
    width: "100%",
    // boxShadow: "10px 5px 40px 20px #f3f3f3",
    boxShadow: "10px 5px 40px 20px #dfdfdf",
    borderRadius: 0,

    "&:hover": {
      boxShadow: "10px 5px 40px 20px #dfdfdf",
    },

  },

  cardActions: { padding: "20px 0" },
  smallDetails: { marginRight: "5px" },
}));

export default ({
  content,
  onVote,
  votedCandidate,
  event,
  voting,
  updatingVotes,
  eventEnded,
}) => {
  const [state, setState] = useState({
    confirmModel: false
  });
  const { width } = useWindowDimensions();
  const classes = useStyles();
  const history = useHistory();
  const voteBtnDisabled =
    eventEnded ||
    (voting && votedCandidate.candidateId !== content.candidateId);
  const viewContestant = () =>
    navigateToPage(`/${event.id}/${content.candidateId}`, history);

  const onConfirm = async () => {
    const response = await fullUrlService({
      method: "POST",
      path: `${process.env.EVENT_PUBLIC_API}/events/${event.id}/candidates/${content.id || content.candidateId
        }/vote`,
    });

    Toast({
      message: response.message,
      type: response.error ? "error" : "success",
      duration: 2000,
    });
    if (!response.error) {
      setState({
        ...state,
        confirmModel: false
      })
    }
  }

  const toggleModel = (action) => {
    setState({
      ...state,
      confirmModel: false
    });
  };

  return (
    <Card className={classes.root}>
      <CardActionArea onClick={viewContestant}>
        <div className={classes.contestantCard}>
          <img
            src={content.imgUrl || content.photo}
            alt="contestant image"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </div>

        <CardContent
          className={classes.cartContent}
          style={{
            height: width > 600 ? "4vh" : "5vh",
          }}
        >
          <Typography
            variant="subtitle2"
            component="div"
            flexWrap
            style={{ lineHeight: 1.2, fontSize: 18 }}
          >
            {(
              content.name || `${content.firstName} ${content.lastName}`
            ).substring(0, 45)}
          </Typography>
          <Grid container justifyContent="space-between">
            <Typography
              variant="subtitle1"
              style={{
                color: grayColor[0],
                fontSize: 16,
              }}
            >
              {updatingVotes &&
                votedCandidate.candidateId === content.candidateId ? (
                <Skeleton
                  variant="text"
                  style={{ height: "50px", width: "150px" }}
                />
              ) : (
                `${formatNumber(content.votes) || 0} Votes`
              )}
            </Typography>
            {content.contestantCode ? (
              <Typography variant="h6">
                <strong>{content.contestantCode}</strong>
              </Typography>
            ) : null}
          </Grid>

          <br />
        </CardContent>
      </CardActionArea>
      <CardActions className={classes.cardActions}>
        <Button
          onClick={() => {
            if (!event.payment.required) {
              setState({
                ...state,
                confirmModel: true,
                confirmTitle: "Confirm Vote",
                confirmMessage: `confirm that you want to vote ${content.firstName} ${content.lastName}`,
                danger: true,
                confirmText: "Yes",
              })
            } else {
              onVote(content);
            }
          }}
          className={classes.cardVoteBtn}
          disabled={eventEnded}
          style={{
            display: "inline",
            width: "70%",
            // backgroundColor: "#1876D1",
            backgroundColor: eventEnded ? "#5D5C62" : "#1876D1",
            color: "#fff",
            margin: " 0 20%",
            height: "50px",
          }}
        >
          {voting && votedCandidate.candidateId === content.candidateId ? (
            <PulseLoader size={7} margin={3} color={"#fff"} loading={voting} />
          ) : (
            "Vote"
          )}
        </Button>
      </CardActions>
      <ConfirmDialog
        open={state.confirmModel || false}
        title={state.confirmTitle}
        message={state.confirmMessage}
        confirmText={state.confirmText}
        danger={state.danger}
        onCancel={() => toggleModel({})}
        onConfirm={onConfirm}
        loading={state.loading}
      />
    </Card>

  );
};
