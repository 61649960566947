import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, Button, Divider } from "@mui/material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { firestore } from "../../../config/firebase";
import Loading from "../../../components/LoadingTickets";
import { fullUrlAction } from "../../../redux/action";
import { FETCH_EVENT } from "../../../redux/events/types";
import Toast from "../../../components/MessageToast";
import fullUrlService from "../../../services/fullUrlService";

const style = {
  height: "100%",
  width: "30%",
  position: "relative",
  "@media(max-width:600px)": {
    width: "90%",
    padding: "10px",
    paddingTop: "30px",
  },
  padding: "0px",
  bgcolor: "background.paper",
  // border: '2px solid #000',
  borderRadius: "10px",
  boxShadow: 4,
  p: 4,
  marign: "100px 0px",
};

const avatar = {
  position: "absolute",
  left: "40%",
  "@media(max-width:600px)": { left: "43%" },
  padding: "10px",
  top: -30,
  backgroundColor: "red",
  objectFit: "cover",
};

const typography = {
  padding: "20px 0px",
};

export default ({ eventId }) => {
  const eventInfo = useSelector((state) => state.event);
  const query = new URLSearchParams(document.location.search);
  const dispatch = useDispatch();
  const [ticke, setTicke] = useState([]);
  const [signedTickets, setSignedTickets] = useState([]);

  const tx_ref = query.get("tx_ref");
  useEffect(() => {
    if (
      !eventInfo.data.results ||
      eventId !== (eventInfo.data.results && eventInfo.data.results.id)
    ) {
      dispatch(
        fullUrlAction({
          method: "GET",
          path: `${process.env.EVENT_PUBLIC_API}/events/${eventId}`,
          actionType: FETCH_EVENT,
        })
      );
    }
    const PayPreview = firestore
      .collection(process.env.TICKECT_COLLECTION)
      .where("confirmationNumber", "==", tx_ref);
    PayPreview.onSnapshot((snap) => {
      const data = snap.docs.map((ticket) => ({
        ...ticket.data(),
        id: ticket.id,
      }));
      const ticketing = data.filter((item) => item.ticketLink);
      if (ticketing.length === data.length) {
        setTicke(data);
      }
    });
    return PayPreview;
  }, []);

  async function fetchSignedValues(tickets) {
    const signedValues = await Promise.all(
      tickets.map(async (ticket) => {
        const qrCodeSignedLink = await fullUrlService({
          method: "GET",
          path: `${process.env.SIGNED_LINK_URL}?url=${ticket.qrCodeUrl}`,
        });
        return {
          ...ticket,
          qrCodeUrl: qrCodeSignedLink.results.signedUrl,
        };
      })
    );
    return signedValues;
  }

  useEffect(() => {
    if (ticke.length > 0) {
      fetchSignedValues(ticke).then((signedValues) => {
        setSignedTickets(signedValues);
      });
    }
  }, [ticke]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (signedTickets.length === 0) {
        Toast({
          message:
            "Your ticket(s) has not been generated contact the adminstrator...",
          type: "error",
          duration: 4000,
        });
      }
    }, 120000);

    return () => {
      clearTimeout(timeout);
    };
  }, [signedTickets]);

  const DownloadPdf = async (url, id) => {
    const signedUrl = await fullUrlService({
      method: "GET",
      path: `${process.env.SIGNED_LINK_URL}?url=${url}`,
    });
    dispatch(
      fullUrlAction({
        method: "GET",
        path: `${process.env.EVENT_PUBLIC_API}/tickets/${id}/downloaded`,
        actionType: "DOWNLOADED_TICKETS",
      })
    );
    const link = document.createElement("a");
    link.href = signedUrl.results.signedUrl;

    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return eventInfo.loading ||
    !eventInfo.data.results ||
    eventInfo.data.results.eventLink ||
    signedTickets.length <= 0 ? (
    <Loading />
  ) : (
    <div style={{ position: "relative" }}>
      <Box
        sx={{
          backgroundColor: "rgb(9, 47, 83)",
          padding: "5px",
          marginBottom: "20px",
          position: "sticky",
          top: "70px",
          zIndex: 100,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "5px 20px",
            width: "fit-content",
          }}
        >
          <Box
            sx={{
              paddingRight: "20px",
              "@media(max-width:600px)": { display: "none" },
            }}
          >
            <img
              src={eventInfo.data.results.coverPhoto}
              style={{
                width: "200px",
                height: "100px",
                objectFit: "cover",
                objectPosition: "top center",
              }}
            />
          </Box>
          <Box>
            <Typography
              variant="h5"
              sx={{ color: "white", fontWeight: "500px" }}
            >
              {eventInfo.data.results.name}
            </Typography>
            <Typography
              variant="span"
              sx={{ color: "white", padding: "15px 0px", fontSize: "15px" }}
            >
              {eventInfo.data.results.startDate.slice(0, 10)}
            </Typography>
            <Typography sx={{ color: "white", fontWeight: "400px" }}>
              {eventInfo.data.results.venue}
            </Typography>
          </Box>
        </Box>
      </Box>
      {signedTickets.map((ticket) => (
        <div
          id="#tickets"
          key={ticket.id}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "88px 0px",
          }}
        >
          <Box sx={style} elevation={0}>
            <Box>
              <Box>
                <div
                  style={{
                    backgroundImage: `url(${eventInfo.data.results.coverPhoto})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                    backgrooundRepeat: "none",
                    position: "absolute",
                    left: "0",
                    top: "0",
                    height: "50px",
                    width: "100%",
                    borderRadius: "10px 10px 0px 0px",
                    backgroundColor: "rgb(9, 47, 83)",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "20px",
                      color: "white",
                      padding: "10px 20px",
                    }}
                  >
                    {eventInfo.data.results.name}
                  </Typography>
                  <Button
                    onClick={() => DownloadPdf(ticket.ticketLink, ticket.id)}
                    sx={{
                      backgroundColor: "white",
                      color: "rgb(9, 47, 83)",
                      ":hover": {
                        backgroundColor: "white",
                        color: "rgb(9, 47, 83)",
                      },
                      marginRight: "20px",
                      height: "35px",
                    }}
                    endIcon={<FileDownloadOutlinedIcon />}
                  >
                    Tickets
                  </Button>
                </div>
                <Box></Box>

                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={typography}>
                      <span style={{ fontSize: "15px", opacity: 0.6 }}>
                        Ticket Tier:
                      </span>
                      <br /> {ticket.tierName}
                    </Typography>
                    <Typography sx={typography}>
                      <span style={{ fontSize: "15px", opacity: 0.6 }}>
                        Ticket Id:
                      </span>
                      <br /> {ticket.ticketItemNumber}
                    </Typography>
                  </Box>
                  <Divider />
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={typography}>
                      <span style={{ fontSize: "15px", opacity: 0.6 }}>
                        Ticket Order Number:
                      </span>
                      <br /> {ticket.ticketOrderNumber}
                    </Typography>
                    <Typography sx={typography}>
                      <span style={{ fontSize: "15px", opacity: 0.6 }}>
                        Ticket Owner:
                      </span>
                      <br /> {ticket.customer.firstName}{" "}
                      {ticket.customer.lastName}
                    </Typography>
                  </Box>
                  <Divider />
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={typography}>
                      <span style={{ fontSize: "15px", opacity: 0.6 }}>
                        Ticket Price:
                      </span>
                      <br /> {ticket.tierPrice} RWF
                    </Typography>
                    <Typography sx={typography}>
                      <span style={{ fontSize: "15px", opacity: 0.6 }}>
                        Ticket amount Paid:
                      </span>
                      <br /> {ticket.tierPrice} RWF
                    </Typography>
                  </Box>
                  <Divider />
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={ticket.qrCodeUrl}
                      alt="event QR code"
                      style={{ width: "200px", height: "200px" }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </div>
      ))}
    </div>
  );
};
