import React from "react";
import { Link } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    padding: "22vh 0",
    textAlign: "center",
    width: "100%",
  },
  content: {
    display: "inline-block",
    opacity: 0.7,
  },
  buttonContainer: {
    textAlign: "center",
    width: "100%",
  },

  backTo: {
    height: "45px",
    textAlign: "center",
    textTransform: "none",
  },
  links: {
    textDecoration: "none",
  },
});

export default ({ title, path, isIndividual, pathName, customElements }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Typography variant="h6" align="center">
          {title || "No Found"}
        </Typography>
        {customElements}
      </div>
      <br />
      <br />
      {path && (
        <Link to={path} className={classes.links}>
          <Button
            className={classes.backTo}
            variant="contained"
            color="primary"
          >
            {isIndividual ? (
              <Typography variant="subtitle1">{pathName}</Typography>
            ) : (
              <Typography variant="subtitle1">Back to {pathName}</Typography>
            )}
          </Button>
        </Link>
      )}
    </div>
  );
};
