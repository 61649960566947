import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { useHistory } from "react-router";
import { Typography } from "@mui/material";
import {
  navigateToPage,
  useWindowDimensions,
} from "../../../helpers/helperMethods";
import EventCard from "../../../components/Cards/EventCard";
import { scrollBar } from "../../../assets/styles/base";

export default ({ content, mainLink, ...rest }) => {
  const history = useHistory();
  const { width } = useWindowDimensions();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        overflowY: "none",
      }}
    >
      <ImageList
        style={{
          display: "grid",
          // flexDirection: "row",
          gridTemplateColumns: "repeat(auto-fill, minmax(290px, 1fr))",
          padding: "10px 0",
          gap: "10px",
          overflowY: "unset",
          // height: width > 600 ? "65vh" : "58vh",
          position: "relative",
          ...scrollBar,
        }}
      >
        {content.length > 0 ? (
          content.map((item, index) => (
            <ImageListItem
              key={index}
              onClick={() =>
                mainLink
                  ? navigateToPage(`/${mainLink}/${item.id}`, history)
                  : navigateToPage(`/${item.id}`, history)
              }
              style={{ cursor: "pointer" }}
            >
              <EventCard isListElt content={item} {...rest} />
            </ImageListItem>
          ))
        ) : (
          <Typography
            variant="h4"
            style={{
              paddingTop: "3vh",
              paddingBottom: "3vh",
              color: "grey",
              // margin: "-10px",
            }}
          >
            No Events
          </Typography>
        )}
      </ImageList>
    </div>
  );
};
