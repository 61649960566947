import { useState, useEffect } from "react";
import moment from "moment";

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
};

export const openLinkInNewTab = (url) =>
  window.open(url || "https://demand.noneho.com/");

export const navigateToPage = (url, history) => history.push(url);

export const formatEvents = (array) => {
  const result = [];
  const categories = [
    "Voting",
    "Concert",
    "Sport",
    "Meeting",
    "Press release",
    "Exhibition",
  ];

  // eslint-disable-next-line no-restricted-syntax
  for (const category of categories) {
    // eslint-disable-next-line no-continue
    if (!category) continue;

    const categoryObj = {
      categoryName: category,
      categoryEvents: array
        .filter((elt) => elt.category === category)
        .map((elt) =>({
            id: elt.id,
            name: elt.name,
            venue: elt.venue || "unknown",
            startDate: elt.startDate ||"2022-12-28T08:07:36.471Z",
            endDate: elt.endDate || "2023-01-14T08:07:36.471Z",
            description: elt.description || "",
            paymentRequired: elt.payment ? elt.payment.required : false,
            organization: elt.organizer || elt.createdBy,
            category: elt.category,
            imgUrl: elt.coverPhoto,
          })),
    };
    result.push(categoryObj);
  }
  return result;
};

export const formatDate = (date, format) =>
  moment(date).format(format || "MMM Do YYYY");

export const formatTime = (date, format) =>
  moment.utc(date).add(moment.duration(2, 'hours')).format(format || "  hh:mm A");

export const formatYoutubeUrl = (url) => {
  const splitUrl = url.split("/");
  return `https://www.youtube.com/embed/${splitUrl[splitUrl.length - 1]}`;
};

export const formatNumber = (data) =>
  data === 0 || !data ? 0 : String(data).replace(/(.)(?=(\d{3})+$)/g, "$1,");
