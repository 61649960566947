import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import HomePage from "../views/pages/ContestantPage";
import EventsPage from "../views/pages/EventsPage";
import PayConfirmPage from "../views/pages/PayConfirmPage";
import BuyTicket from "../views/pages/BuyTicket";
import TicketPreview from "../views/pages/TIcketPreview";
import TermsAndConditionsPage from "../views/pages/TermsAndConditions";

const AppRoute = () => (
  <BrowserRouter>
    <div className="app">
      <Switch>
        <Route exact path="/" component={EventsPage} />
        <Route exact path="/terms-conditions" component={TermsAndConditionsPage} />
        <Route exact path="/payment/complete" component={PayConfirmPage} />
        <Route exact path="/:eventId" component={EventsPage} />
        <Route exact path="/:eventId/buy-ticket" component={BuyTicket} />
        <Route exact path="/:eventId/ticket-preview" component={TicketPreview} />
        <Route exact path="/:eventId/:contestantId" component={HomePage} />
      </Switch>
    </div>
  </BrowserRouter>
);

export default AppRoute;
