import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import Popper from "@mui/material/Popper";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import { NavLink } from "react-router-dom";

export default ({ candidates, eventId }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [searchResult, setSearchResults] = React.useState([]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };

  const handleInputChange = (e) => {
    const results = candidates.filter((el) =>
      el.firstName.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setSearchResults(results);
  };

  return (
    <Box
      sx={{
        m: 1,
        px: 1,
        display: "flex",
        justifyContent: "flex-end",
        fontsize: "20px",
      }}
    >
      <FormControl
        variant="outlined"
        onClick={handleClick}
        sx={{
          ml: 2,
          width: { sm: 450, xs: "100%" },
        }}
        onBlur={handleClick}
      >
        <InputLabel sx={{ color: "#092f53" }} htmlFor="search-candidate">
          Search contestant
        </InputLabel>
        <OutlinedInput
          id="search-candidate"
          sx={{ color: "#044368" }}
          onChange={handleInputChange}
          endAdornment={
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          }
          label="search-candidate"
        />
      </FormControl>
      <Popper placement="bottom" transition open={open} anchorEl={anchorEl}>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper
              sx={{
                width: { sm: 450, xs: "95%" },
                ml: 1,
                borderRadius: "10px",
              }}
            >
              <List
                sx={{
                  width: "100%",
                  maxWidth: 450,
                  bgcolor: "background.paper",
                  borderRadius: "5px",
                  pr: 1,
                }}
              >
                {searchResult.map((el, index) => (
                  <NavLink
                    key={index}
                    to={`/${eventId}/${el.id || el.candidateId}`}
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <ListItem
                      alignItems="flex-start"
                      sx={{
                        ":hover": {
                          cursor: "pointer",
                          backgroundColor: "rgba(0,0,0,0.02)",
                        },
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar
                          alt={el.firstName}
                          src={el.photo}
                          variant="square"
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={el.firstName}
                        secondary={
                          <React.Fragment>
                            {el.category || ""}
                            <br></br>
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              Votes: {el.votes}
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                  </NavLink>
                ))}
              </List>
            </Paper>
          </Fade>
        )}
      </Popper>
    </Box>
  );
};
