import React from "react";
import "../../assets/styles/components/navbar.css";
import { useHistory } from "react-router";
import { IconButton } from "@mui/material";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import { openLinkInNewTab } from "../../helpers/helperMethods";
import Logo from "../../assets/images/LogoNames.png";

export default () => {
	const history = useHistory();

	const navigateTo = (url) => history.push(url);

	return (
		<div className="navRoot">
			<div className="navContent">
				{window.location.pathname !== "/" && (
					<IconButton onClick={() => history.goBack()}>
						<ChevronLeft className="back-btn" fontSize="large" />
					</IconButton>
				)}
				{/* <span className="navLogo" onClick={() => navigateTo("/")}>
          noneho.events
        </span> */}
				<div style={{ width: "200px", height: "100%" }}>
					<img
						onClick={() => history.push("/")}
						src={Logo}
						alt="Noneho events logo"
						style={{ width: "100%", height: "100%", cursor: "pointer" }}
					/>
				</div>
			</div>

			<div
				className="navButton"
				onClick={() => openLinkInNewTab("https://demand.noneho.com/events/new")}
			>
				Create Event
			</div>
		</div>
	);
};
