import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure();

export default (props) => toast(props.message, {
    position: props.position || "top-right",
    autoClose: props.duration || 2000,
    hideProgressBar: !props.progress || true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    newestOnTop: true,
    type: props.type,
    onClose: props.onClose,
  });
