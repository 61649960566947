import React from "react";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
	root: {
		padding: "5%",
		"@media (max-width: 600px)": {
			padding: "10px 2%",
		},
	},
	inputElt: {
		padding: "10px 0",
		width: "100%",
		display: "grid",
	},
	supportedTitle: {
		width: "100%",
		textAlign: "center",
		borderBottom: "1px solid #888888",
		lineHeight: "0.1em",
		margin: "10px 0 20px",
	},
	supportedInline: {
		background: "#fff",
		padding: "0 10px",
	},
	avatar: {
		width: "20%",
		height: "10vh",
		cursor: "pointer",
		objectFit: "cover",
		"@media (max-width:600px)": {
			width: 50,
			height: 50,
		},
	},
}));

export default ({ onChoosePaymentMethod }) => {
	const classes = useStyles();

	return (
		<Box className={classes.root}>
			<Typography variant="h5" align="center">
				Choose Payment Method
			</Typography>
			<Box className={classes.inputElt}>
				<Typography variant="body1" className={classes.supportedTitle}>
					<span className={classes.supportedInline}>
						Supported Payment Methods
					</span>
				</Typography>
			</Box>
			<Box className={classes.inputElt}>
				<Stack
					direction="row"
					spacing={1}
					alignItems="center"
					justifyContent="space-evenly"
				>
					<Avatar
						alt="MTN Rwanda"
						src="https://storage.googleapis.com/nonehoidentity.appspot.com/ClientsLogos/mtn-logo.jpg"
						variant="rounded"
						className={classes.avatar}
						onClick={() => onChoosePaymentMethod("mobile")}
					/>
					<Avatar
						alt="Airtel Rwanda"
						src="https://storage.googleapis.com/nonehoidentity.appspot.com/ClientsLogos/airtel-logo.jpg"
						variant="rounded"
						className={classes.avatar}
						onClick={() => onChoosePaymentMethod("mobile")}
					/>
					<Avatar
						alt="Visa card"
						src="https://storage.googleapis.com/nonehoidentity.appspot.com/ClientsLogos/visa-logo.jpg"
						variant="rounded"
						className={classes.avatar}
						onClick={() => onChoosePaymentMethod("card")}
					/>
					<Avatar
						alt="Master card"
						src="https://storage.googleapis.com/nonehoidentity.appspot.com/ClientsLogos/mastercard-logo.jpg"
						variant="rounded"
						className={classes.avatar}
						onClick={() => onChoosePaymentMethod("card")}
					/>
					{/* <Avatar
            alt="Paypal"
            src="https://storage.googleapis.com/nonehoidentity.appspot.com/ClientsLogos/paypallogo.png"
            variant="rounded"
            className={classes.avatar}
            onClick={() => onChoosePaymentMethod("paypal")}
          /> */}
				</Stack>
			</Box>
		</Box>
	);
};
